<template lang="html">
  <section class="rules">
    <v-card flat class="mx-5">
      <v-form v-model="valid" ref="form">
        <v-row no-gutters class="justify-center">
          <v-col class="my-6">
            <!-- Stammdaten -->
            <v-card flat class="ml-2 mr-6">
              <v-card-title class="text-overline pt-1 darkgrey-tableheader">
                <h4>Stammdaten</h4>
              </v-card-title>
              <v-list class="py-0 mx-3">
                <v-list-item>
                  <v-list-item-content class="py-0">
                    <v-row>
                      <v-col class="py-2">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Vorname</h4>
                        </v-list-item-title>
                        <v-text-field autofocus id="firstname" class="ma-0 pa-0" v-model="firstname" @change="updateEmail()"
                          :rules="combineRules(formRules.required, formRules.string)"></v-text-field>
                      </v-col>
                      <v-col class="py-2">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Nachname</h4>
                        </v-list-item-title>
                        <v-text-field id="lastname" class="ma-0 pa-0" v-model="lastname" @change="updateEmail()"
                          :rules="combineRules(formRules.required, formRules.string)"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="py-0">
                    <v-row>
                      <v-col class="py-2">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Email</h4>
                        </v-list-item-title>
                        <v-text-field id="email" class="ma-0 pa-0" v-model="email"
                          placeholder="vorname.nachname@seqis.com"
                          :rules="combineRules(formRules.required, formRules.string, formRules.email, this.checkIfUserEmailAlreadyExists)"></v-text-field>
                      </v-col>

                      <v-col class="py-2">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Personalnummer</h4>
                        </v-list-item-title>
                        <v-text-field id="personnelNumber" class="ma-0 pa-0" v-model="personnelNumber"
                          :rules="combineRules(formRules.required, formRules.string, this.checkIfPersonnelNumberAlreadyExists)"
                          placeholder="123"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="py-0">
                    <v-row>
                      <v-col class="py-2">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Adresse</h4>
                        </v-list-item-title>
                        <v-textarea :auto-grow="true" rows="1" id="address" class="ma-0 pa-0 pt-1" v-model="address"
                          placeholder="Straße Hausnr., Ort, Land" :rules="formRules.string"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="py-0">
                    <v-row>
                      <v-col class="py-2">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Eintrittsdatum</h4>
                        </v-list-item-title>
                        <v-text-field id="employedSince" class="ma-0 pa-0" placeholder="DD.MM.YYYY"
                          :value="employedSince.textfield"
                          :rules="combineRules(formRules.required, formRules.date)" ref="employedSince"
                          @input="updateEmployedSinceDateValue($event)">
                          
                          <template v-slot:append>
                            <v-menu ref="employedSinceMenu" v-model="employedSince.picker" :close-on-content-click="false"
                              transition="slide-y-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="mt-1 grow-icon" size="20">mdi-calendar</v-icon>
                              </template>
                              <v-date-picker color="#c00000" no-title scrollable v-model="employedSince.date"
                                @input="updateEmployedSinceTextfieldValue($event)" />
                            </v-menu>
                          </template>
                        </v-text-field>

                      </v-col>
                      <v-col class="py-2">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Geburtstag</h4>
                        </v-list-item-title>
                        <v-text-field id="birthday" class="ma-0 pa-0" placeholder="DD.MM.YYYY" ref="birthday"
                          :value="birthday.textfield"
                          :rules="combineRules(formRules.required, formRules.date)"
                          @input="updateBirthdayDateValue($event)">

                          <template v-slot:append>
                            <v-menu ref="birthdayMenu" v-model="birthday.picker" :close-on-content-click="false"
                              transition="slide-y-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="mt-1 grow-icon" size="20">mdi-calendar</v-icon>
                              </template>
                              <v-date-picker color="#c00000" no-title scrollable v-model="birthday.date"
                                @input="updateBirthdayTextfieldValue($event)" />
                            </v-menu>
                          </template>
                        </v-text-field>
                        <!-- <v-text-field autofocus id="birthday" class="ma-0 pa-0" v-model="birthday.textfield"></v-text-field> -->
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>

            <!-- Teamzuordnung -->
            <v-card flat class="ml-2 mr-6">
              <v-card-title class="text-overline pt-1 pb-4 darkgrey-tableheader">
                <h4>Teamzuordnung</h4>
              </v-card-title>
              <v-list class="py-0 mx-3">
                <v-list-item>
                  <v-list-item-content class="py-0">
                    <v-row>
                      <v-col class="py-2">

                        <v-container class="pt-0 pl-0 mr-0 pr-0">
                          <v-list-item-title class="text-overline mb-1">
                            <h4>Team</h4>
                          </v-list-item-title>
                          <v-chip v-if="selectedTeam" small close @click:close="clearTeam()" color="#c00000" id="selectedTeam"
                            class="white--text mb-1 mt-1">
                            {{ selectedTeam.name }}
                          </v-chip>

                          <v-container v-else class="ma-0 pa-0 mt-9"></v-container>

                          <v-divider class=""></v-divider>

                          <v-list id="selectableTeams">
                            <template v-for="team in teams">
                              <v-chip :disabled="selectedTeam != undefined" small :key="team.id" class="mr-2 mb-1" :id="'team_'+team.id"
                                @click="selectTeam(team)">
                                {{ team.name }}
                              </v-chip>
                            </template>
                          </v-list>
                          <v-input class="ma-0 pa-0" v-model="selectedTeam"
                            :rules="[selectedTeam => selectedTeam != undefined || 'Ein Team muss gesetzt werden!']">
                          </v-input>
                        </v-container>
                      </v-col>
                      <v-col class="py-2">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Monatsabschlussverantwortlicher</h4>
                        </v-list-item-title>
                        <v-text-field id="monthClosingResponsible" placeholder="vorname.nachname@seqis.com"
                          class="ma-0 pa-0" :rules="combineRules(formRules.required, formRules.string, formRules.email)"
                          v-model="monthClosingResponsible"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>

          <v-col class="my-6">
            <!-- Arbeitszeit  -->
            <v-card flat class="ml-2 mr-6">
              <v-card-title class="text-overline pt-1 darkgrey-tableheader">
                <h4>Arbeitszeit</h4>
              </v-card-title>
              <v-list class="py-0 mx-3">
                <v-list-item>
                  <v-list-item-content class="py-0">
                    <v-row no-gutters>
                      <v-list-item-title class="text-overline mb-1">
                        <h4>Wochenmodell </h4>
                      </v-list-item-title>
                      <v-col id="testarea" class="justify-center">
                        <v-row>
                          <v-col cols="2">
                            <v-text-field id="wochenstunden" label="Wochenstunden" type="number" min="0" max="60"
                              :rules="combineRules(formRules.required, formRules.positiveNumber)" step="0.5"
                              v-model.number="weeklyHours" @input="updateDailyHours()"></v-text-field>
                          </v-col>
                          <v-col cols="auto">
                            <v-text-field id="monday" label="Montag" type="number" min="0" max="12"
                              :rules="combineRules(formRules.required, formRules.positiveNumber)" step="0.5"
                              v-model.number="dailyHours.hoursMonday" @input="updateWeeklyHours()"></v-text-field>
                          </v-col>
                          <v-col cols="auto">
                            <v-text-field id="tuesday" label="Dienstag" type="number" min="0" max="12"
                              :rules="combineRules(formRules.required, formRules.positiveNumber)" step="0.5"
                              v-model.number="dailyHours.hoursTuesday" @input="updateWeeklyHours()"></v-text-field>
                          </v-col>
                          <v-col cols="auto">
                            <v-text-field id="wednesday" label="Mittwoch" type="number" min="0" max="12"
                              :rules="combineRules(formRules.required, formRules.positiveNumber)" step="0.5"
                              v-model.number="dailyHours.hoursWednesday" @input="updateWeeklyHours()"></v-text-field>
                          </v-col>
                          <v-col cols="auto">
                            <v-text-field id="thursday" label="Donnerstag" type="number" min="0" max="12"
                              :rules="combineRules(formRules.required, formRules.positiveNumber)" step="0.5"
                              v-model.number="dailyHours.hoursThursday" @input="updateWeeklyHours()"></v-text-field>
                          </v-col>
                          <v-col cols="auto">
                            <v-text-field id="friday" label="Freitag" type="number" min="0" max="12"
                              :rules="combineRules(formRules.required, formRules.positiveNumber)" step="0.5"
                              v-model.number="dailyHours.hoursFriday" @input="updateWeeklyHours()"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content class="py-0">
                    <v-row>
                      <v-col class="py-2" cols="6">
                        <v-list-item-title class="text-overline mb-1">
                          <h4>Überstundenpauschale </h4>
                        </v-list-item-title>
                        <v-text-field type="number" min="0" id="overTimeFlat" class="ma-0 pa-0" label=""
                          :rules="combineRules(formRules.required, formRules.positiveNumber)"
                          v-model.number="overTimeFlat"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>

            <!-- Interne Projekte -->
            <!-- TODO ich kann mich mit dem Control nicht anfreunden. Es wär doch viel einfacher, wenn man einfach auf einen chip klickt, und der wird dann rot (=gewählt) und wenn ich wieder draufklick ist er wieder grau (=nicht gewählt). dann gibts kein problem mit der reihenfolge, dann springt nix, dann brauch ich nicht x-erln, ...  -->
            <v-card flat class="ml-2 mr-6">
              <v-card-title class="text-overline pt-1 pb-3 darkgrey-tableheader">
                <h4>Interne Projekte</h4>
              </v-card-title>
              <v-container class="pt-1 pb-0 pl-7" >
                <v-row no-gutters class="mb-1" id="selectedProjects">
                  <v-col v-for="selectedProject in selectedProjects" :key="selectedProject.id" cols="auto"
                    class="mr-2 mb-1">
                    <v-chip small close color="#c00000" class="white--text"
                      @click:close="removeProjectFromSelection(selectedProject.id)">
                      {{ selectedProject.name }}
                    </v-chip>
                  </v-col>
                  <v-container v-if="selectedProjects.length == 0" class="mb-1"> </v-container>
                </v-row>

                <v-divider class="mr-4"></v-divider>

                <v-list id="selectableProjects">
                  <template v-for="project in projectsToSelect">
                    <v-chip small :key="project.id" class="mr-2 mb-1" @click="selectedProjectIDs.push(project.id);">{{
                      project.name }}</v-chip>
                  </template>
                </v-list>
              </v-container>
            </v-card>

            <!-- Zusätzliche Informationen -->
            <v-expansion-panels class="ml-2 mr-6 pt-3" flat>
              <v-expansion-panel class="pa-0 ma-0" flat >
                <v-expansion-panel-header expand-icon="mdi-menu-down" flat class="pa-0 ma-0 pr-12 mr-12" id="additonalInformationPanel" >
                  <v-card-title class="text-overline darkgrey-tableheader">
                    <h4>Zusätzliche Informationen (Rollen, Manuelle GZK Korrektur, Urlaub)</h4>
                  </v-card-title>
                </v-expansion-panel-header>

                <v-expansion-panel-content class="pa-0 ma-0 mr-10 mt-1" flat>
                  <v-list class="py-0 pl-1">
                    <v-list-item class="pa-0 ma-0">
                      <v-list-item-content class="py-0">
                        <v-row>
                          <v-col class="py-2">
                            <v-list-item-title class="text-overline mb-1">
                              <h4>Urlaubsanspruch</h4>
                            </v-list-item-title>
                            <v-text-field id="urlaubsanspruch" type="number" min="0" class="ma-0 pa-0" label=""
                              v-model.number="urlaubsanspruch" :rules="combineRules(formRules.required, formRules.positiveNumber)"></v-text-field>
                          </v-col>
                          <v-col class="py-2">
                            <v-list-item-title class="text-overline mb-1">
                              <b>Urlaub Titel </b>
                            </v-list-item-title>
                            <v-text-field id="urlaubTitel" class="ma-0 pa-0" label="" ref="urlaubTitel"
                              v-model="urlaubTitel" :rules="combineRules(formRules.required, formRules.string)"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0 ma-0">
                      <v-list-item-content class="py-0">
                        <v-row>
                          <v-col class="py-2" cols="6">
                            <v-list-item-title class="text-overline mb-1">
                              <h4>Monatliche Gleitzeitkonto Korrektur </h4>
                            </v-list-item-title>
                            <v-text-field type="number" id="monthlyFlexTimeCorrection" class="ma-0 pa-0" label=""
                              ref="monthlyFlexTimeCorrection" v-model.number="monthlyFlexTimeCorrection"
                              :rules="combineRules(formRules.required)"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-container class="py-0 pl-1">
                    <v-card-title color="black" class="text-overline pt-0 pb-0 pl-0">
                      <h4>Rollen</h4>
                    </v-card-title>
                    <v-col id="testarea" class="justify-center pt-2 pl-0">
                      <v-card-text class="pa-0">
                        <v-row no-gutters>
                          <v-col cols="auto" class="chip mb-2 mr-2">
                            <v-chip small id="glRoleButton" @click.native="roles.GL = !roles.GL"
                              :color="getChipColor(roles.GL)" class="white--text">Geschäftsleitung</v-chip>
                          </v-col>
                          <v-col cols="auto" class="chip mr-2">
                            <v-chip small id="tlRoleButton" @click.native="roles.TL = !roles.TL"
                              :color="getChipColor(roles.TL)" class="white--text">Teamlead</v-chip>
                          </v-col>
                          <v-col cols="auto" class="chip mr-2">
                            <v-chip small id="hrRoleButton" @click.native="roles.HR = !roles.HR"
                              :color="getChipColor(roles.HR)" class="white--text">Human Resources</v-chip>
                          </v-col>
                          <v-col cols="auto" class="chip mr-2">
                            <v-chip small id="faRoleButton" @click.native="roles.FA = !roles.FA"
                              :color="getChipColor(roles.FA)" class="white--text">Finance</v-chip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-col>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="resetForm" type="button" @click.native="initializeForm()" outlined :loading="loading"
          color="#c00000">Formular zurücksetzen
        </v-btn>

        <v-btn id="createUser" type="button" @click.native="createUser()" :loading="loading" color="#c00000"
          class="white--text ml-8" :disabled="!valid">Neuen Mitarbeiter anlegen
        </v-btn>

        <v-snackbar id="successMessage" v-if="newUserCreated" v-model="newUserCreated" top color="green">
          <v-progress-circular indeterminate color="white"> </v-progress-circular>
          New User created successfully
        </v-snackbar>
        
        <v-snackbar id="errorMessage" v-if="errorHandling.showError" v-model="errorHandling.showError" top
          color="red accent-2">
          <v-progress-circular indeterminate color="white"> </v-progress-circular>
          {{ errorHandling.errorMessage }}
        </v-snackbar>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script lang="js">
import * as hrClient from '@client/client.hr';
import FormRules from '@mixins/FormRules.vue';
import dayjs from '@util/dayjs';

export default {
  name: 'CreateEmployee',
  mixins: [FormRules],

  data() {
    return {
      firstname: null,
      lastname: null,
      email: null,
      personnelNumber: null,
      address: null,

      employedSince: {
        textfield: null,
        date: null,
        picker: false
      },
      birthday: {
        textfield: null,
        date: null,
        picker: false
      },

      // Teamzuordnung
      selectedTeam: null,
      teams: [],
      monthClosingResponsible: null,

      overTimeFlat: null, // Überstundenpauschale
      urlaubTitel: null,

      weeklyHours: null,
      dailyHours: {
        hoursMonday: null,
        hoursTuesday: null,
        hoursWednesday: null,
        hoursThursday: null,
        hoursFriday: null,
      },

      // Interne Projekte
      selectedProjectIDs: [],
      projects: [],

      urlaubsanspruch: null,
      monthlyFlexTimeCorrection: null,

      roles: {
        GL: false,
        TL: false,
        HR: false,
        FA: false
      },

      existingUsers: [],

      errorHandling: {
        showError: false,
        errorMessage: ''
      },

      newUserCreated: false,
      loading: false,
      valid: true
    }
  },

  created() {
    // TODO es gibt kein visuelles "loading", das entspricht an sich nicht unserem standard
    this.loadData();
  },

  mounted() {
    this.initializeForm();
  },

  computed: {
    selectedProjects() {
      const selectedProjects = [];
      for (const projectID of this.selectedProjectIDs) {
        const project = this.projects.find(project => project.id === projectID);
        if (project) {
          selectedProjects.push(project);
        }
      }
      return selectedProjects;
    },

    projectsToSelect() {
      if (this.projects != undefined) {
        return this.projects.filter(project => !this.selectedProjectIDs.includes(project.id) && project.teamID == null);
      }
      return [];
    }
  },

  methods: {
    async loadData() {
      const teamsPromise = hrClient.getTeams();
      const existingUsersPromise = hrClient.getUsers();
      const projectsPromise = hrClient.getInternalProjects();
      
      const [teams, existingUsers, projects] = await Promise.all([teamsPromise, existingUsersPromise, projectsPromise]);

      this.existingUsers = existingUsers;
      this.projects = projects;
      
      this.teams = teams.filter(team => {
        const project = this.projects.find(project => project.teamID == team.id);
        return project != undefined;
      });
    },

    initializeForm() {
      this.$refs.form.reset();

      this.$draf(() => {
        // das setzen der Default Werte darf erst nach dem repaint vom form.reset() passieren
        this.urlaubsanspruch = 25;
        this.monthlyFlexTimeCorrection = 0;
        this.urlaubTitel = 'Initialer Urlaubsanspruch';
        this.overTimeFlat = 0;
        this.weeklyHours = 38.5;
        this.selectedProjectIDs = [...global.DEFAULT_INTERNAL_PROJECTS];

        for (const day of Object.keys(this.dailyHours)) {
          this.dailyHours[day] = 7.7;
        }

        // TODO known BUG wenn man 2x hintereinander initializeForma ausführt, ohne dazwischen employedSince zu ändern, ist das Feld danach leer :-/
        this.employedSince.textfield = dayjs().format('DD.MM.YYYY');
        this.employedSince.date = dayjs().format('YYYY-MM-DD');

        this.$refs.form.resetValidation();
        this.$refs.form.validate();
      });
    },

    getChipColor(chipSelected) {
      return chipSelected ? '#c00000' : 'lightgrey';
    },

    selectTeam(team) {
      this.selectedTeam = team;
      const teamProject = this.projects.find(project => project.teamID == team.id);

      if (teamProject != undefined) {
        const teamlead = this.existingUsers.find(user => user.id == teamProject.leader.id);
        this.monthClosingResponsible = teamlead.email;
      } else {
        this.monthClosingResponsible = null;
      }
    },

    clearTeam() {
      this.selectedTeam = null;
      this.monthClosingResponsible = null;
    },

    async createUser() {
      this.loading = true;

      if (this.$refs.form.validate() == false) {
        this.loading = false;
        return;
      }

      const roles = Object.entries(this.roles).map(([roleName, roleValue]) => roleValue == true ? roleName : undefined).filter(role => role); // remove empty array indizes

      const newUser = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        address: this.address,
        personnelNumber: this.personnelNumber,

        employedSince: this.employedSince.date,
        birthday: this.birthday.date,

        teamID: this.selectedTeam.id,
        monthClosingResponsible: this.monthClosingResponsible,
        
        wochenmodell: [
          this.dailyHours.hoursMonday,
          this.dailyHours.hoursTuesday,
          this.dailyHours.hoursWednesday,
          this.dailyHours.hoursThursday,
          this.dailyHours.hoursFriday,
        ],
        projectIDs: this.selectedProjectIDs,
        
        urlaubsanspruch: this.urlaubsanspruch,
        urlaubTitel: this.urlaubTitel,
        overTimeFlat: this.overTimeFlat,
        monthlyFlexTimeCorrection: this.monthlyFlexTimeCorrection,

        roles: roles,
      };

      try {
        await hrClient.createUser(newUser);
      } catch (error) {
        this.loading = false;
        this.errorHandling.errorMessage = error.response.data;
        this.errorHandling.showError = true;
        return;
      }

      this.newUserCreated = true;
      this.loading = false;
    },

    removeProjectFromSelection(idToRemove) {
      this.selectedProjectIDs = this.selectedProjectIDs.filter(id => id !== idToRemove);
    },

    updateDailyHours() {
      this.weeklyHours = this.weeklyHours > 60 ? 60 : this.weeklyHours;
      const newDailyHours = Math.round(this.weeklyHours / 5 * 10) / 10;

      for (const day of Object.keys(this.dailyHours)) {
        this.dailyHours[day] = newDailyHours;
      }
    },

    updateWeeklyHours() {
      let newWeeklyHours = 0;

      for (const day of Object.keys(this.dailyHours)) {
        this.dailyHours[day] = this.dailyHours[day] > 12 ? 12 : this.dailyHours[day];
        newWeeklyHours += this.dailyHours[day];
      }
      newWeeklyHours = newWeeklyHours > 60 ? 60 : newWeeklyHours;
      this.weeklyHours = Math.round(newWeeklyHours * 10) / 10;
    },

    updateEmail() {
      if (!this.firstname || !this.lastname) {
        this.email = null;
        return;
      }
      this.email = `${this.firstname.toLowerCase()}.${this.lastname.toLowerCase()}@seqis.com`
    },

    checkIfUserEmailAlreadyExists() {
      if (this.existingUsers.some((user) => this.email === user.email)) {
        return 'Dieser User exisitiert bereits im Moco!';
      } else {
        return true;
      }
    },

    checkIfPersonnelNumberAlreadyExists() {
      if (this.existingUsers.some((user) => this.personnelNumber === user.masterData.personnelNumber)) {
        return 'Diese Personalnummer ist bereits vergeben!';
      } else {
        return true;
      }
    },

    updateEmployedSinceDateValue(value) {
      if (this.$refs.employedSince.validate() && dayjs(value, 'DD.MM.YYYY').isValid()) {
        this.employedSince.textfield = value;
        this.employedSince.date = dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
    },

    updateEmployedSinceTextfieldValue(value) {
      this.employedSince.date = value;
      this.employedSince.textfield = dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
      this.employedSince.picker = false;
    },

    updateBirthdayDateValue(value) {
      if (this.$refs.birthday.validate() && dayjs(value, 'DD.MM.YYYY').isValid()) {
        this.birthday.textfield = value;
        this.birthday.date = dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
    },

    updateBirthdayTextfieldValue(value) {
      this.birthday.date = value;
      this.birthday.textfield = dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
      this.birthday.picker = false;
    },
  }
}
</script>

<style>
.grow-icon:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}
</style>