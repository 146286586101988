<template lang="html">
  <v-expansion-panel v-show="dataset.show" :class="'pa-0 pl-3 '+ getStatusColor(dataset.status)">
    <section :cy-label="'activityPanel_'+this.structure[0].columns[0].data(dataset)">
      <v-expansion-panel-header :class="'pt-0 pb-0 ' + styleClass" :color="color">
        <section id='dynamicActivityNodePanelHeader'>
          <v-row no-gutters class="subtitle-1 d-flex flex-row">
            <v-col v-for="column in this.structure[0].columns" :key="column.name">
              <v-card :set="data = column.data(dataset)" class="pa-2 d-flex flex-column" flat color='transparent'>
                <label id="nodePanelHeaderCardLabel" class="grey--text overline">{{column.label(dataset)}}</label>
                <label id="nodePanelHeaderCardContent">
                  <div v-if="typeof data === 'boolean'">
                    <v-icon v-if="data" data-cy="activityPanelIconBillable">mdi-check</v-icon>
                  </div>
                  <div v-else data-cy="activityPanelContent">{{data}}</div>
                </label>
              </v-card>
            </v-col>
          </v-row>
        </section>
      </v-expansion-panel-header>

      <v-expansion-panel-content color="white" class="pa-0 ma-0" eager>
        <div v-if="childStructure.length > 0">
          <v-expansion-panels :value="expandedPanels" multiple hover accordion class="pa-0">
            <DynamicActivityNode id="dynamicActivityChildNode" v-for="childNode in dataset.childNodes" :key="childNode.key" 
              :structure="childStructure" :dataset="childNode" :expanded="expanded" :presences="presences"
              :entryUpdateEnabled="entryUpdateEnabled" :projectActivitiesRef="projectActivitiesRef">
            </DynamicActivityNode>
          </v-expansion-panels>
        </div>
        <div v-else>
          <v-col class="pa-0">
            <v-row class="pb-2" id="errorMessageBanner" v-if="dataset.errors && dataset.errors.length > 0">
              <v-sheet v-for="(error, index) in dataset.errors" :key="index"
                class="pa-2 mt-1 mb-1 text-center white--text" min-width="100%" elevation="5"
                :color="getStatusColor('error')">
                {{error.message}}
              </v-sheet>
            </v-row>
            <v-row class="pb-2" id="warningMessageBanner" v-if="dataset.warnings && dataset.warnings.length > 0">
              <v-sheet v-for="(warning, index) in dataset.warnings" :key="index"
                class="pa-2 mt-1 mb-1  text-center white--text" min-width="100%" elevation="5"
                :color="getStatusColor('warning')">
                {{warning.message}}
              </v-sheet>
            </v-row>
          </v-col>
          <v-data-table :headers="tableHeaders" :items="displayedActivities" item-key="id" :item-class="calcItemBackgroundColor"
            class="elevation-1" id="activityTable" hide-default-footer :items-per-page="100"
            @click:row="editActivity">
            <template v-slot:item.highlightedDescription="{ item }">
              <div v-html="item.highlightedDescription"></div>
            </template>
            <template v-slot:item.billable="{ item }">
              <v-icon v-if="item.billable" class="mr-2" data-cy="activityTableIconBillable">mdi-check</v-icon>
            </template>
            <template v-slot:item.status="{ item }">
              <v-icon v-if="item.billed"  class="mr-2">mdi-currency-eur</v-icon>
              <v-icon v-else-if="!item.isEditable" class="mr-2">mdi-lock</v-icon>
            </template>
          </v-data-table>
        </div>
      </v-expansion-panel-content>

      <CreateEditPresenceActivity ref="editActivity" :dayData="{presences}" :alterOriginalEntries="true"></CreateEditPresenceActivity>

    </section>
  </v-expansion-panel>
</template>

<script lang="js">
  import _ from "lodash";
  
  import ActivityFilter from '@/mixins/ActivityFilter.vue';
  import StatusColorCalculator from '@/mixins/StatusColorCalculator.vue';
  import CreateEditPresenceActivity from '@components/CreateEditPresenceActivity.vue';

  export default {
    name: 'DynamicActivityNode',
    props: ['structure', 'dataset', 'presences', 'expanded', 'entryUpdateEnabled', 'projectActivitiesRef'],
    mixins: [ActivityFilter, StatusColorCalculator],
    components: {
      CreateEditPresenceActivity
    },
    data() {
      return {
        expandedPanels: [],
        tableHeaders: [
           {
            text: 'Beschreibung',
            value: 'highlightedDescription',
          }, 
          {
            text: 'h',
            value: 'hours',
            width: "5em",  
          },
          {
            text: 'Verr.',
            value: 'billable',
            width: "3em",
            sortable: false
          },
          {
            text: 'Status',
            value: 'status',
            width: "3em",
            sortable: false
          }
        ]
      }
    },
    created() {
      this.$root.$on('activityFilterStructureChange', () => {
        this.expandedPanels = [];
      });

      this.projectActivitiesRef.$on('expandAll', this.expandAll);
      this.projectActivitiesRef.$on('collapseAll', this.collapseAll);
    },
    beforeDestroy() {
      this.projectActivitiesRef.$off('expandAll', this.expandAll);
      this.projectActivitiesRef.$off('collapseAll', this.collapseAll);
    },

    computed: {
      displayedActivities() {
        return this.dataset.activities.filter((activity) => activity.show);
      },
      styleClass() {
        const styleClass = this.structure[0].style?.class;
        return styleClass ? styleClass(this.dataset) : '';
      },
      color() {
        const color = this.structure[0].style?.color;
        return color ? color(this.dataset) : this.getBackgroundColor('default');
      },
      childStructure() {
        if (this.structure.length > 1) {
          return _.tail(this.structure);
        } 
        return [];
      }
    },

    methods: {
      editActivity (activity) {
        if (this.entryUpdateEnabled == false) {
          return;
        }
        this.$refs.editActivity.editActivity(activity, true); 
      },
      expandAll() {
        if (this.dataset.childNodes !== undefined) {
          this.expandedPanels = [...Array(this.dataset.childNodes.length).keys()];
        }
      },
      collapseAll() {
        this.expandedPanels = [];
      }
    },
  }
</script>
<style lang="scss">
#genericWiP .col:first-of-type {
  font-weight: bold;
}
.highlightedText {
  background: yellow;
}
</style>