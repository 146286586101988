<template lang='html'>
  <section class='week'>

    <v-expansion-panel-header class="pt-0 pb-0" color="white">
      <section class='weekSummary'>
        <v-row no-gutters style="d-flex flex-row" class="subtitle-1">
          <v-col cols="auto" class="">
            <v-card class="pa-2 font-weight-bold d-flex flex-column" flat color='transparent'>
              <label id="calendarWeekHeader" class="overline darkgrey-tableheader">{{fromToOfWeek}}</label>
              <label id="calendarWeek">KW{{weekData.calendarWeek}}</label>
            </v-card>
          </v-col>
          <v-col cols="auto" class="d-flex flex-grow-1">
            <UserHoursSummary :displayData="weekData" :visibility="{hideHoursWorked: false}" :extrapolateFlexTime="extrapolateFlexTime"></UserHoursSummary>
          </v-col>
        </v-row>
      </section>
    </v-expansion-panel-header>

    <v-expansion-panel-content color="white" class="pa-0 ma-0">
      <section class='days'>
        <v-expansion-panels multiple hover accordion class="pa-0" :value="expandedDays">
            <v-expansion-panel v-for="day in weekData.days" :key="day.date" :class="'pa-0 pl-3 '+ getStatusColor(day.status)">
              <UserDay :dayData="day" :extrapolateFlexTime="extrapolateFlexTime" :entryUpdateEnabled="entryUpdateEnabled"></UserDay>
            </v-expansion-panel>
        </v-expansion-panels>
      </section>
    </v-expansion-panel-content>
  </section>
</template>

<script lang="js">
  import UserDay from '@components/user/UserDay.vue';
  import UserHoursSummary from '@components/user/UserHoursSummary.vue';
  import StatusColorCalculator from '@/mixins/StatusColorCalculator.vue'

  import _ from 'lodash';
  import dayjs from '@util/dayjs';

  export default {
    name: 'weekSummary',
    props: ['weekData', 'extrapolateFlexTime', 'entryUpdateEnabled', 'monthTableRef'],
    mixins: [StatusColorCalculator],
    data() {
      return {
        expandedDays: []
      }
    },

    created() {
      this.monthTableRef.$on('expandAllDays', this.expandAllDays);
      this.monthTableRef.$on('collapseAllDays', this.collapseAllDays);
    },

    beforeDestroy() {
      this.monthTableRef.$off('expandAllDays', this.expandAllDays);
      this.monthTableRef.$off('collapseAllDays', this.collapseAllDays);
    },

    components: {
      UserDay,
      UserHoursSummary
    },
    computed: {
      fromToOfWeek: function() {
        return dayjs(_.head(this.weekData.days).date).format('DD.') + '-' + dayjs(_.last(this.weekData.days).date)
          .format('DD.MM.');
      }
    },
    methods: {
      expandAllDays() {
        this.expandedDays = [];
        for (let i = 0; i < this.weekData.days.length; i++) {
          this.$set(this.expandedDays, i, i);
        }
      },

      collapseAllDays() {
        this.expandedDays = [];
      }
    },
  }
</script>